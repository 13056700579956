import React from "react";

function Footer() {
  return (
    <footer className="bg-dark py-4 mt-auto">
      <div className="container px-5">
        <div className="row align-items-center justify-content-between flex-column flex-sm-row">
          <div className="col-auto">
            <div className="small m-0 text-white">
              
            </div>
          </div>
          <div className="col-auto">
            <h6 className="link-light d-inline small" href="#!">
            Dr. Claudio Tramon
            </h6>
            <span className="text-white mx-1">&middot;</span>
            <h6 className="link-light  d-inline small" href="#!">
            Dr. Ignacio Tramon
            </h6>
            <span className="text-white mx-1">&middot;</span>
            <h6 className="link-light  d-inline small" href="#!">
            Dra. Nicole Robles
            </h6>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
