import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-scroll";

function Header() {
  return (
    <section
      className="bg-light py-5 section-full image-perro-gato"
      id="section_header"
    >
      <div className="container px-5">
        <div className="row gx-5 align-items-center justify-content-center">
          <div className="col-lg-8 col-xl-7 col-xxl-6">
            <div className="my-5 text-center text-xl-start text-white">
              <h1 className="display-5 fw-bolder mb-2 text-white">
                Clínica Veterinaria Pichos
              </h1>
              <p class="lead fw-bolder">
                Desde 1992 al servicio de sus mascotas.
              </p>

              <div className="col-auto">
                <h6 className="d-inline small" href="#!">
                  Dr. Claudio Tramon
                </h6>
                <span className="mx-1">&middot;</span>
                <h6 className="d-inline small" href="#!">
                  Dr. Ignacio Tramon
                </h6>
                <span className="mx-1">&middot;</span>
                <h6 className="d-inline small" href="#!">
                  Dra. Nicole Robles
                </h6>
              </div>

              <div className="d-grid mt-3 gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                <a
                  className="btn btn-primary btn-lg px-4 me-sm-3"
                  href="tel:56322497632"
                >
                  <FontAwesomeIcon icon={faPhone} className="me-2" /> 32 2497632
                </a>
                <a
                  className="btn btn-secondary btn-lg px-4"
                  href="m.me/293435430831934"
                >
                  <FontAwesomeIcon
                    icon={faFacebookMessenger}
                    className="text-white me-2"
                  />{" "}
                  Envíanos un mensaje
                </a>
              </div>
            </div>
            <div className="col-12">
              <a className="btn btn-info btn-lg text-white px-4" href="#">
                <FontAwesomeIcon icon={faMapMarkedAlt} className="me-2" />
                <Link
                  to="section_ubicacion"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={250}
                >
                  {" "}
                  Buenos aires 796 Valparaíso
                </Link>
              </a>
            </div>
          </div>
          <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"></div>
        </div>
      </div>
    </section>
  );
}

export default Header;
