import React from 'react';

function Section(){
    return (
      <section className="py-5" id="section_servicios">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder mb-0 text-success">
                Servicios destacados
              </h2>
            </div>
            <div className="col-lg-8">
              <div className="row gx-5">
                <div className="col mb-5 h-100">
                  <ul class="list-group">
                    <li class="list-group-item">Cirugías generales y esterilizaciones</li>
                    <li class="list-group-item">Cirugías traumatológicas</li>
                    <li class="list-group-item">Radiografía digital</li>
                    <li class="list-group-item">Ecografías</li>
                    <li class="list-group-item">Vacunas y desparasitaciones</li>
                    <li class="list-group-item">Laboratorio hematológico, perfil bioquímicos y hemogramas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        <hr />
      </section>
    );
}

export default Section;