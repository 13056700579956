import React from "react";
import {
    Link
} from "react-scroll";

function Nav() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light border-bottom" id="nav">
      <div className="container px-5">
        <a className="navbar-brand" href="index.html">
          <img
            className="img-fluid rounded-3 my-1"
            src="logo-pichos-155x55.png"
            alt="..."
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <a className="nav-link" href="#">
                <Link
                  to="section_servicios"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={250}
                >
                  Servicios
                </Link>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                <Link
                  to="section_ubicacion"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={250}
                >
                  Ubicación
                </Link>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
