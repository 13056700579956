import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdkdQ49UJ9WDB2ZY2d2C0xk-12dTrPXkY",
  authDomain: "pichos-bf799.firebaseapp.com",
  projectId: "pichos-bf799",
  storageBucket: "pichos-bf799.appspot.com",
  messagingSenderId: "384668891353",
  appId: "1:384668891353:web:3936366b6d65655de0a6c8",
  measurementId: "G-33X72H4H4L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, "notification_received");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
