import React from 'react';

function Section(){
    return (
      <section className="py-5" id="features">
        <div className="container px-5 my-5">
          <div className="row gx-5">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h2 className="fw-bolder mb-0 text-success">
                Horario
              </h2>
            </div>
            <div className="col-xl-8">
              <div className="row gx-5">
                <div className="col-lg-6 mb-5 h-100">
                  <ul class="list-group">
                    <li class="list-group-item"><h5 class="mb-1">Lunes - Viernes</h5></li>
                    <li class="list-group-item">10:30 - 14:00</li>
                    <li class="list-group-item">15:00 - 17:00</li>
                  
                  </ul>
                </div>
                <div className="col-lg-6 mb-5 h-100">
                  <ul class="list-group">
                    <li class="list-group-item"><h5 class="mb-1">Sábados</h5></li>
                    <li class="list-group-item">10:30 - 14:00</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Section;