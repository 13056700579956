/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

function Section() {
  return (
    <section className="py-5" >
      <div className="container px-5 my-5" id="container_ubicacion">
        <div className="row gx-5" id="section_ubicacion">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <h2 className="fw-bolder mb-0 text-success">
            Ubicación  
            </h2>
            Buenos aires 796 Valparaiso.
            <br />
            <br />
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3344.271381171158!2d-71.62108298452917!3d-33.049321480890676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9689e0d1bda4c1f9%3A0x393d34a7532b72c5!2sCl%C3%ADnica%20Veterinaria%20Pichos!5e0!3m2!1ses-419!2scl!4v1670349697922!5m2!1ses-419!2scl"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <hr />
    </section>
  );
}

export default Section;
