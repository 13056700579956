import React from "react";
import { MessengerChat } from "react-messenger-chat-plugin";

function Messenger(props) {
  return (
    <div>
      <MessengerChat
        pageId="293435430831934"
        language="es_ES"
        //themeColor={"#000000"}
        //bottomSpacing={300}
        loggedInGreeting="Hola ¿Tienes alguna pregunta?"
        loggedOutGreeting="¿Tienes alguna pregunta?"
        greetingDialogDisplay={"show"}
        greetingDialogDelay={0}
        debugMode={false}
        onMessengerShow={() => {
          console.log("onMessengerShow");
        }}
        onMessengerHide={() => {
          console.log("onMessengerHide");
        }}
        onMessengerDialogShow={() => {
          console.log("onMessengerDialogShow");
        }}
        onMessengerDialogHide={() => {
          console.log("onMessengerDialogHide");
        }}
        onMessengerMounted={() => {
          console.log("onMessengerMounted");
        }}
        onMessengerLoad={() => {
          console.log("onMessengerLoad");
        }}
      />
    </div>
  );
}

export default Messenger;
