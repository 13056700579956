import React from "react";

//import Layout from "./components/template/Layout";
import Nav from "./components/Nav";
import Header from "./components/Header";
import Servicios from "./components/Servicios";
import Horario from "./components/Horario";
import Mapa from "./components/Mapa";
import Footer from "./components/Footer"
import Messenger from "./components/Messenger"

function App() {
  return (
    <div className="d-flex flex-column h-100">
      <main className="flex-shrink-0">
        <Nav/>
        <Header/>
        <Horario/>
        <Servicios/>
        <Mapa/>
        <Messenger/>
        <Footer/>
      </main>
    </div>
  );
}

export default App;
